import { dom } from "vnet-dom";



export const searchFilter = () => {
  let form = dom.findFirst('.search-filter');
  let searchOpts = dom.findFirst('.search-options');
  let ppiWrap = dom.findFirst('.ppi-wrap');

  if(!form) return;
  initOpenMobileFilter();

  if (!form || !searchOpts || !ppiWrap) return;

  init({ form, searchOpts, ppiWrap });

}




const initOpenMobileFilter = () => {
  let wrap = dom.findFirst('.search-results');
  if (!wrap) return;

  let filter = dom.findFirst('.filter-col');
  let results = dom.findFirst('.results-col');
  if (!filter || !results) return;

  let timers = [];

  dom.onClick('.js-open-filter', e => {
    clearMobileFilterAnimations(filter, results, timers);

    if (wrap.classList.contains('filter-is-visible')) {
      hideMobileFilter(e.target, filter, results, wrap, timers);
    } else {
      displayMobileFilter(e.target, filter, results, wrap, timers);
    }
  });
}



const hideMobileFilter = (btn, filter, results, wrap, timers) => {
  dom.removeClass(wrap, 'filter-is-visible');
  dom.addCss(filter, { transition: '0s', display: 'block' });
  dom.addCss(results, { transition: '0s' });
  timers.push(setTimeout(() => {
    dom.addCss([filter, results], { transition: '.2s', transform: 'translateX(-100%)' });
  }, 10));
  timers.push(setTimeout(() => {
    filter.removeAttribute('style');
    results.removeAttribute('style');
  }, 210));
}


const displayMobileFilter = (btn, filter, results, wrap, timers) => {

  dom.addClass(wrap, 'filter-is-visible');
  dom.addCss(filter, { transition: '0s', display: 'block', transform: 'translateX(-100%)' });
  dom.addCss(results, { transition: '0s', transform: 'translateX(-100%)' });

  timers.push(setTimeout(() => {
    dom.addCss([filter, results], { transition: '.2s', transform: 'none' });
  }, 10));

  timers.push(setTimeout(() => {
    dom.addCss(results, { display: 'none' });
  }, 220));

}



const clearMobileFilterAnimations = (filter, results, timers) => {
  filter.removeAttribute('style');
  results.removeAttribute('style');
  timers.forEach(timer => clearTimeout(timer));
}


const init = ({ form, searchOpts, ppiWrap }) => {
  form.onsubmit = function(){
      let region_select = dom.findFirst('#region_select', form);
      if(typeof region_select != 'undefined') {
          if(!region_select.value.length) {
              region_select.parentNode.removeChild(region_select);
          }
      }
     let salary_input = dom.findFirst('#salary_input', form);
     if(typeof salary_input != 'undefined') {
         if(!salary_input.value.length) {
             salary_input.parentNode.removeChild(salary_input);
         }
     }
     let experience_null = dom.findFirst('#experience_null', form);
     if(typeof experience_null != 'undefined') {
         if(experience_null.checked) {
             experience_null.parentNode.removeChild(experience_null);
         }
     }
  }
  //initPpi({ form, searchOpts, ppiWrap });
}



const initPpi = ({ form, searchOpts, ppiWrap }) => {
  dom.onClick('.ppi-rm', e => {
    e.preventDefault();
    rmPpi({ form, btn: e.currentTarget });
    checkHasOpts({ form, searchOpts, ppiWrap });
  }, ppiWrap);

  dom.onClick('.clear-search-options', e => {
    e.preventDefault();
    clearAllPpi({ form, searchOpts, ppiWrap });
  }, searchOpts);
}


const checkHasOpts = ({ searchOpts, ppiWrap }) => {
  let ppi = dom.findAll('.ppi', ppiWrap);
  if (!ppi || !ppi.length) {
    dom.removeClass(searchOpts, 'has-options');
  }
}



const clearAllPpi = ({ form, searchOpts, ppiWrap }) => {
  let items = dom.findAll('.ppi-rm', ppiWrap);
  if (!items || !items.length) return;
  items.forEach(item => {
    dom.dispatch(item, 'click');
  });
  form.submit();
}



const rmPpi = ({ form, btn }) => {
  let target = btn.dataset.target;
  let def = btn.dataset.def || '';
  if (!target) return;
  target = target.split(',');
  def = def.split(',');
  setPpiValues({ form, target, def });
  btn.parentNode.parentNode.removeChild(btn.parentNode);
}


const setPpiValues = ({ form, target, def }) => {
  target.forEach((item, i) => {
    item = dom.findFirst(item);
    if (!item) return;


    if (item.tagName === 'SELECT') {
      let value = def[i] ? def[i] : '';
      setPpiSelectValue({ select: item, value });
      return;
    }


    if (item.type === 'text') {
      let value = def[i] ? def[i] : '';
      setPpiTextValue({ input: item, value });
      return;
    }

    if (item.type === 'checkbox' || item.type === "radio") {
      setPpiCheckbox({ target: item, valIds: def });
      return;
    }
  });
}



const setPpiSelectValue = ({ select, value }) => {
  select.value = value;
  if (select.classList.contains('custom-select')) {
    $(select).niceSelect('update');
  }
}


const setPpiTextValue = ({ input, value }) => {
  input.value = value;
}


const setPpiCheckbox = ({ target, valIds }) => {
  let valInputs = valIds.map(id => dom.findFirst(id)).filter(item => item);
  target.checked = false;
  if (valInputs && valInputs.length) {
    valInputs.forEach(input => input.checked = true);
  }
}

import { dom } from "vnet-dom";

export const searchTips = container => {
  let resumeInput = document.getElementById('resume-query-input');
  var vacancyRegionSelectDesktop = document.getElementById('vacancy-region-select-desktop');
  var vacancyRegionSelectDevice = document.getElementById('vacancy-region-select-device');
  var resumeRegionSelectDesktop = document.getElementById('resume-region-select-desktop');
  var resumeRegionSelectDevice = document.getElementById('resume-region-select-device');
  let ajaxTimeout;
  if (typeof resumeInput == 'undefined') return;


  vacancyRegionSelectDesktop.onchange = function(){
      vacancyRegionSelectDevice.value = vacancyRegionSelectDesktop.value;
      $(vacancyRegionSelectDevice).niceSelect('update');
  };
  vacancyRegionSelectDevice.onchange = function(){
      vacancyRegionSelectDesktop.value = vacancyRegionSelectDevice.value;
      $(vacancyRegionSelectDesktop).niceSelect('update');
  };
  resumeRegionSelectDesktop.onchange = function(){
      resumeRegionSelectDevice.value = resumeRegionSelectDesktop.value;
      $(resumeRegionSelectDevice).niceSelect('update');
  };
  resumeRegionSelectDevice.onchange = function(){
      resumeRegionSelectDesktop.value = resumeRegionSelectDevice.value;
      $(resumeRegionSelectDesktop).niceSelect('update');
  };

  resumeInput.oninput = function(){
      clearTimeout(ajaxTimeout);
      var tips = this.parentNode.querySelectorAll('.input-tips')[0];

      if(this.value.length >= 3) {
          ajaxTimeout = setTimeout(function(){
              var httpRequest = false;

              if (window.XMLHttpRequest) {
                  httpRequest = new XMLHttpRequest();
                  if (httpRequest.overrideMimeType) {
                      httpRequest.overrideMimeType('text/xml');
                  }
              } else if (window.ActiveXObject) {
                  try {
                      httpRequest = new ActiveXObject("Msxml2.XMLHTTP");
                  } catch (e) {
                      try {
                          httpRequest = new ActiveXObject("Microsoft.XMLHTTP");
                      } catch (e) {}
                  }
              }

              if (!httpRequest) {
                  alert('Не вышло :( Невозможно создать экземпляр класса XMLHTTP ');
                  return false;
              }
              httpRequest.onreadystatechange = function() {
                  if (httpRequest.readyState == 4) {
                      if (httpRequest.status == 200) {
                          var data = JSON.parse(httpRequest.responseText);
                          tips.innerHTML = '';
                          if(data.length) {
                              for(var i in data) {
                                  var a = document.createElement('a');
                                  a.className = 'input-tips__item';
                                  a.href = '/resume/list?'
                                  if(data[i].type == 'category') {
                                      a.href += 'category=' + data[i].id;
                                  }
                                  else { // query
                                      a.href += 'query=' + data[i].text;
                                  }
                                  if(window.innerWidth >= 768) {
                                      if(resumeRegionSelectDesktop.value) {
                                          a.href += '&region=' + vacancyRegionSelectDesktop.value;
                                      }
                                  }
                                  else {
                                      if(resumeRegionSelectDevice.value) {
                                          a.href += '&region=' + vacancyRegionSelectDevice.value;
                                      }
                                  }
                                  if(data[i].text.length > 48) {
                                      data[i].text = data[i].text.substr(0, 48) + '...';
                                  }
                                  a.innerHTML = data[i].text;
                                  if(data[i].type == 'category') {
                                      var span = document.createElement('span');
                                      span.innerHTML = 'Категория';
                                      span.className = 'input-tips__item-right';
                                      a.appendChild(span);
                                  }
                                  tips.appendChild(a);
                              }
                              tips.style.display = 'block';
                          }
                      }
                  }
              };
              var regionQuery = '';
              if(window.innerWidth >= 768) {
                  if(resumeRegionSelectDesktop.value) {
                      regionQuery = '&region=' + vacancyRegionSelectDesktop.value;
                  }
              }
              else {
                  if(resumeRegionSelectDevice.value) {
                      regionQuery = '&region=' + vacancyRegionSelectDevice.value;
                  }
              }
              httpRequest.open('GET', '/searchtips?type=resume&query=' + resumeInput.value + regionQuery, true);
              httpRequest.send(null);
              tips.style.display = '';
          }, 500);
      }
      else {
          tips.style.display = '';
      }
  };

  let vacancyInput = document.getElementById('vacancy-query-input');
  if (typeof vacancyInput == 'undefined') return;

  vacancyInput.oninput = function(){
      clearTimeout(ajaxTimeout);
      var tips = this.parentNode.querySelectorAll('.input-tips')[0];
      if(this.value.length >= 3) {
          ajaxTimeout = setTimeout(function(){
              var httpRequest = false;

              if (window.XMLHttpRequest) {
                  httpRequest = new XMLHttpRequest();
                  if (httpRequest.overrideMimeType) {
                      httpRequest.overrideMimeType('text/xml');
                  }
              } else if (window.ActiveXObject) {
                  try {
                      httpRequest = new ActiveXObject("Msxml2.XMLHTTP");
                  } catch (e) {
                      try {
                          httpRequest = new ActiveXObject("Microsoft.XMLHTTP");
                      } catch (e) {}
                  }
              }

              if (!httpRequest) {
                  alert('Не вышло :( Невозможно создать экземпляр класса XMLHTTP ');
                  return false;
              }
              httpRequest.onreadystatechange = function() {
                    if (httpRequest.readyState == 4) {
                        if (httpRequest.status == 200) {
                            var data = JSON.parse(httpRequest.responseText);
                            tips.innerHTML = '';
                            if(data.length) {
                                for(var i in data) {
                                    var a = document.createElement('a');
                                    a.className = 'input-tips__item';
                                    a.href = '/vacancies/list?'
                                    if(data[i].type == 'category') {
                                        a.href += 'category=' + data[i].id;
                                    }
                                    else { // query
                                        a.href += 'query=' + data[i].text;
                                    }
                                    if(window.innerWidth >= 768) {
                                        if(vacancyRegionSelectDesktop.value) {
                                            a.href += '&region=' + vacancyRegionSelectDesktop.value;
                                        }
                                    }
                                    else {
                                        if(vacancyRegionSelectDevice.value) {
                                            a.href += '&region=' + vacancyRegionSelectDevice.value;
                                        }
                                    }
                                    if(data[i].text.length > 48) {
                                        data[i].text = data[i].text.substr(0, 48) + '...';
                                    }
                                    a.innerHTML = data[i].text;
                                    if(data[i].type == 'category') {
                                        var span = document.createElement('span');
                                        span.innerHTML = 'Категория';
                                        span.className = 'input-tips__item-right';
                                        a.appendChild(span);
                                    }
                                    tips.appendChild(a);
                                }
                                tips.style.display = 'block';
                            }
                        }
                    }
              };
              var regionQuery = '';
              if(window.innerWidth >= 768) {
                  if(vacancyRegionSelectDesktop.value) {
                      regionQuery = '&region=' + vacancyRegionSelectDesktop.value;
                  }
              }
              else {
                  if(vacancyRegionSelectDevice.value) {
                      regionQuery = '&region=' + vacancyRegionSelectDevice.value;
                  }
              }
              httpRequest.open('GET', '/searchtips?type=vacancy&query=' + vacancyInput.value + regionQuery, true);
              httpRequest.send(null);
              tips.style.display = '';
          }, 500);
      }
      else {
          tips.style.display = '';
      }
  };
}

import { dom } from "vnet-dom";

import { companyVacanciesSlider } from "./companyVacanciesSlider";
import { newsSlick } from "./newsSlick";



export const initSlick = container => {
  companyVacanciesSlider(container);
  newsSlick(container);
}



export const isSlickInit = $slider => {
  return $slider.hasClass('slick-init');
}



export const slick = ($slider, sets) => {
  $slider.addClass('slick-init');
  $slider.slick(sets);
}



export const slickDestroy = $slider => {
  $slider.removeClass('slick-init');
  $slider.slick('unslick');
}



export const slickOnBreakpoint = (breakpoint, $slider, sets) => {
  breakpoint = parseFloat(breakpoint);

  maybeSlickInit(breakpoint, $slider, sets);

  dom.onWindowResize(() => {
    maybeSlickInit(breakpoint, $slider, sets);
  });
}




const maybeSlickInit = (breakpoint, $slider, sets) => {
  let isInit = isSlickInit($slider);

  if (dom.window.innerWidth >= breakpoint && isInit) {
    slickDestroy($slider);
    return;
  }

  if (dom.window.innerWidth < breakpoint && !isInit) {
    slick($slider, sets);
    return;
  }
}
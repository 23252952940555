import { dom } from "vnet-dom";

export const accordeon = container => {
  let items = dom.findAll('.js-accordeon-toggle', container);
  if (!items || !items.length) return;

  items.forEach(item => {
    $(item).on('click', function(e){
        e.preventDefault();
        let $target = $(item).parent().find('.js-accordeon');
        $(item).hide();
        $target.slideDown(300);
    });
  });
}

import { dom } from "vnet-dom";

let messages;



export const initInputTypeImage = container => {
  let items = dom.findAll('.input-type-image', container);
  if (!items || !items.length) return;
  getMessages();
  items.forEach(item => {
    initInputFile(item);
    initValidation(item);
  });
}




const getMessages = () => {
  messages = JSON.parse(back_dates.validate_msg);
}




const initValidation = item => {
  let input = dom.findFirst('input[type=file]', item);
  if (!input) return;

  input.addEventListener('validate-add-error', e => {
    addError(item, input.dataset.errorMsg);
  });

  input.addEventListener('validate-rm-error', e => {
    removeError(item);
  });
}





const initInputFile = item => {
  let uplaod = dom.findFirst('.uplaod-link', item);
  let input = dom.findFirst('input[type=file]', item);
  let rm = dom.findFirst('.rm-link', item);
  let preview = dom.findFirst('.preview', item);
  let flag = dom.findFirst('input[type=hidden]', item);
  let noimage = item.dataset.noimage;

  if (!uplaod || !input || !rm || !preview) return;
  initFileInputElements({ uplaod, input, rm, preview, noimage, flag, item });
}






const initFileInputElements = ({ uplaod, input, rm, preview, noimage, flag, item }) => {
  if(!$(item).hasClass('file-uploaded')) {
      setInputNoimage(preview, noimage, item);
  }
  initClearFileInput({ rm, input, preview, noimage, flag, item });
  input.addEventListener('change', e => {
    removeError(item);
    previewLocalImage({ input, target: preview, item, flag, noimage });
  });
}





const previewLocalImage = ({ input, target, item, flag, noimage }) => {
  if (input.files && input.files[0]) {

    if (!input.files[0].type.includes('image/')) {
      item.classList.remove('file-uploaded');
      input.value = '';
      setInputNoimage(target, noimage, item);
      return;
    }

    var reader = new FileReader();

    reader.onload = (e) => {
      flag.value = '0';
      item.classList.add('file-uploaded');
      target.innerHTML = '';
      let img = dom.create('img', { src: e.target.result });
      target.appendChild(img);
    }

    reader.readAsDataURL(input.files[0]);
  }
}






const addError = (item, msg) => {
  let help = getHelp(item);
  help.innerHTML = msg;
  dom.addClass(item, 'has-error');
}





const getHelp = item => {
  let parent = item.parentNode;
  let childs = parent.children;
  let help;
  for (let i = 0; i < childs.length; i++) {
    if (childs[i].classList.contains('input-help')) {
      help = childs[i];
      break;
    }
  }

  if (!help) {
    help = dom.create('span', { className: 'input-help' });
    parent.appendChild(help);
  }
  return help;
}




const removeError = item => {
  dom.removeClass(item, 'has-error');
  let help = getHelp(item);
  help.innerHTML = '';
}






const setInputNoimage = (preview, src, item) => {
  if (!src) return;
  preview.innerHTML = '';
  preview.appendChild(dom.create('img', { src: src }));
  item.classList.remove('file-uploaded');
  removeError(item);

  let input = dom.findFirst('input[type=file]', item);
  if (!input) return;

  dom.dispatch(input, 'change', { bubbles: true });
}





const initClearFileInput = ({ rm, input, preview, noimage, flag, item }) => {
  dom.onClick(rm, e => {
    e.preventDefault();
    input.value = '';
    flag.value = '1';
    setInputNoimage(preview, noimage, item);
  });
}

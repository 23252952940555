import { dom } from "vnet-dom";



export const clickEvents = () => {
  dom.onClick(dom.body, e => {
    let path = dom.getEventPath(e);
    parseClick(path, e);
  });
}



const parseClick = (path, e) => {
  path.forEach(target => {
    if (!target.tagName) return;

    if (target.classList && target.classList.contains('disabled')) {
      e.preventDefault();
    }

    if (target.classList && target.classList.contains('dismis-modals')) {
      $.fancybox.close();
    }

    if (target.classList && target.classList.contains('activate-plan')) {
      $('#modalConfirmActivate').find('input[name="plan_user_id"]').val(target.dataset.plan);
    }

    if (target.classList && target.classList.contains('js-delete-resume')) {
      $('#delete-resume-form').attr('action', '/jobseeker/' + target.dataset.user_id + '/resume/' + target.dataset.resume_id);
    }

    if (target.classList && target.classList.contains('js-delete-vacancy')) {
      $('#delete-vacancy-form').attr('action', '/employer/' + target.dataset.user_id + '/vacancies/' + target.dataset.vacancy_id);
    }

    if (target.classList && target.classList.contains('js-create-fastreply')) {
      $('#create-fastreply-form').attr('action', '/vacancies/' + target.dataset.vacancy_id + '/createreply');
    }

    if (target.classList && target.classList.contains('js-create-reply')) {
      $('#create-reply-form').attr('action', '/vacancies/' + target.dataset.vacancy_id + '/createreply');
    }

    if (target.classList && target.classList.contains('js-accept-reply')) {
      $('#accept-reply-form').attr('action', '/employer/' + target.dataset.user_id + '/replies/' + target.dataset.reply_id + '/accept');
    }

    if (target.classList && target.classList.contains('js-decline-reply')) {
      $('#decline-reply-form').attr('action', '/employer/' + target.dataset.user_id + '/replies/' + target.dataset.reply_id + '/decline');
    }


    if (target.classList && target.classList.contains('toggle-collapse')) {
      collapse(target, e);
    }

    if (target.classList && target.classList.contains('prevent-default')) {
      e.preventDefault();
    }

    if (target.classList && target.classList.contains('print-page-link')) {
      dom.window.print();
    }
  });
}


const collapse = (item, e) => {
  e.preventDefault();
  if (!canCollapse(item)) return;

  let target = item.dataset.target;
  if (!target) return;
  target = dom.findFirst(target);
  if (!target) return;

  if (item.classList.contains('active')) {
    dom.removeClass(item, 'active');
    $(target).slideUp({ duration: 150, complete: () => dom.removeClass(target, 'active') });
  } else {
    dom.addClass(item, 'active');
    $(target).slideDown({ duration: 150, complete: () => dom.addClass(target, 'active') });
  }
}





const canCollapse = item => {
  let max = parseInt(item.dataset.breakpointMax);
  let min = parseInt(item.dataset.breakpointMin);
  if (!max && !min) return true;
  if (max && min && window.innerWidth < max && window.innerWidth >= min) return true;
  if (max && window.innerWidth < max) return true;
  if (min && window.innerWidth >= min) return true;
  return false;
}

import { dom } from "vnet-dom";

export const speller = container => {
  let inputs = dom.findAll('.js-yandex-speller', container);
  if (!inputs || !inputs.length) return;

  inputs.forEach(input => {
    let inputValue = $(input).val();
    $(input).on('change', function(){
        $.ajax({
          url: 'https://speller.yandex.net/services/spellservice.json/checkText',
          dataType: 'json',
          data: {
            text: $(input).val(),
            lang: 'ru',
            options: 14,
            format: 'plain'
          },
          success: function(data) {
              let inputValue = $(input).val();
              $.each( data, function( key, value ) {
                inputValue = inputValue.replace(value.word, first(value.s));
              });
              $(input).val(inputValue);
          }
        });
    });
  });

  const first = function(array, n) {
      if (array == null)
      return void 0;
    if (n == null)
      return array[0];
    if (n < 0)
      return [];
    return array.slice(0, n);
  };
}

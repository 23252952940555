import { dom } from "vnet-dom";


export const footerCollapseMobile = () => {
  let footer = dom.findFirst('.footer');
  if (!footer) return;

  dom.onClick('.foot-menu-col .title', e => {
    if (dom.window.innerWidth >= 768) return;

    let btn = e.currentTarget;
    let menu = dom.findFirst('.foot-menu', btn.parentNode);

    maybeOpenMenu(btn, menu);
  }, footer);
}



const maybeOpenMenu = (btn, menu) => {
  if (btn.classList.contains('active')) {
    closeMenu(btn, menu);
  } else {
    openMenu(btn, menu);
  }
}



const closeMenu = (btn, menu) => {
  dom.removeClass(btn, 'active');
  $(menu).slideUp({ duration: 200, done: () => dom.removeClass(menu, 'active') });
}


const openMenu = (btn, menu) => {
  dom.addClass(btn, 'active');
  dom.addClass(menu, 'active');
  $(menu).slideDown({ duration: 200 });
}




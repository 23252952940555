import { dom } from "vnet-dom";



export const newsSlick = container => {
  let sliders = dom.findAll('.news-slick', container);
  if (!sliders || !sliders.length) return;
  let $sliders = $(sliders);
  $sliders.slick({
    slidesToShow: 3,
    arrows: false,
    dots: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1.2
        }
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1.03
        }
      }
    ]
  });
}
import { dom } from "vnet-dom";



export const initMenu = () => {
  let header = dom.findFirst('.header');
  let nav = dom.findFirst('.fixed-navbar', header);
  let compensate = dom.create('div', 'compensate-nav');
  dom.addCss(compensate, { display: 'none' });
  header.appendChild(compensate);
  initFixMenu(header, compensate, nav, compensate, menuWrap, scrollMenu);

  let btn = dom.findFirst('.js-open-mobile-menu');
  let mobMenu = dom.findFirst('.mobile-menu');
  let menuWrap = dom.findFirst('.wrapper', mobMenu);
  let scrollMenu = dom.findFirst('.scroll-menu', mobMenu);

  let userBtn = dom.findFirst('.js-open-user-menu');
  let userMobMenu = dom.findFirst('.user-top-menu', header);
  let userMenuWrap = dom.findFirst('.wrapper', userMobMenu);
  let userScrollMenu = dom.findFirst('.scroll-menu', userMobMenu);




  if (btn) {
    dom.onClick(btn, e => {
      e.preventDefault();
      if (!btn.classList.contains('is-active')) {
        if (userBtn && userBtn.classList.contains('is-active')) closeMobMenu(header, userBtn, userMobMenu, nav, compensate, userMenuWrap, userScrollMenu);
        openMobMenu(header, btn, mobMenu, nav, compensate, menuWrap, scrollMenu);
      } else {
        closeMobMenu(header, btn, mobMenu, nav, compensate, menuWrap, scrollMenu);
      }
    });
  }

  if (userBtn) {
    dom.onClick(userBtn, e => {
      if (dom.window.innerWidth >= 992) return;
      e.preventDefault();
      if (!userBtn.classList.contains('is-active')) {
        if (btn && btn.classList.contains('is-active')) closeMobMenu(header, btn, mobMenu, nav, compensate, menuWrap, scrollMenu);
        openMobMenu(header, userBtn, userMobMenu, nav, compensate, userMenuWrap, userScrollMenu);
      } else {
        closeMobMenu(header, userBtn, userMobMenu, nav, compensate, userMenuWrap, userScrollMenu);
      }
    });
  }


}



const openMobMenu = (header, btn, mobMenu, nav, compensate, menuWrap, scrollMenu) => {
  if (!header || !btn || !mobMenu || !nav || !compensate || !menuWrap || !scrollMenu) return;
  let isOverflow = dom.isYOverflow(scrollMenu);
  dom.addClass(mobMenu, 'active');
  dom.addClass(btn, 'is-active');
  dom.addClass(header, 'menu-is-open is-fixed');
  dom.addClass(nav, 'compens-p-right');
  if (!isOverflow) dom.addClass(menuWrap, 'compens-p-right');
  dom.addClass(dom.body, 'vnet-compensate-for-scrollbar over-hidden');
  fixNavbar(header, compensate, nav);
}



const closeMobMenu = (header, btn, mobMenu, nav, compensate, menuWrap, scrollMenu) => {
  if (!header || !btn || !mobMenu || !nav || !compensate || !menuWrap || !scrollMenu) return;
  dom.removeClass(mobMenu, 'active');
  dom.removeClass(btn, 'is-active');
  dom.removeClass(header, 'menu-is-open');
  dom.removeClass([nav, menuWrap], 'compens-p-right');
  dom.removeClass(dom.body, 'vnet-compensate-for-scrollbar over-hidden');
  if (!isHeaderFixPosition(header)) {
    unfixNavbar(header, compensate, nav);
  }
}




const initFixMenu = (header, compensate, nav) => {
  if (!header || !compensate || !nav) return;
  checkFixNav(header, compensate, nav);
  dom.onWindowScroll(e => checkFixNav(header, compensate, nav));
}



const checkFixNav = (header, compensate, nav) => {
  if (isHeaderFixPosition(header)) {
    if (header.classList.contains('is-fixed')) return;
    fixNavbar(header, compensate, nav);
  } else {
    if (!header.classList.contains('is-fixed') || header.classList.contains('menu-is-open')) return;
    unfixNavbar(header, compensate, nav);
  }
}



const isHeaderFixPosition = (header) => {
  let pageOffset = dom.window.pageYOffset;
  let top = header.getBoundingClientRect().top;
  return pageOffset >= top + pageOffset;
}




const fixNavbar = (header, compensate, nav) => {
  dom.addCss(compensate, { display: 'block', height: `${nav.offsetHeight}px` });
  dom.addClass(header, 'is-fixed');
}



const unfixNavbar = (header, compensate, nav) => {
  dom.removeClass(header, 'is-fixed');
  dom.addCss(compensate, { display: 'none' });
}
import "../../css/dev/index.scss";
import { preloader, createAlertModal } from "./components";
import { addInputError, removeInputError } from "./forms/validate";

import { dom } from "vnet-dom";

import { ajaxLoadMore } from "vnet-dom/DOM/ajaxLoadMore";
import { ajaxLoadOnView } from "vnet-dom/DOM/ajaxLoadOnView";
import { ajaxFancybox } from "vnet-dom/DOM/ajaxFancybox";
import { ajaxForm } from "vnet-dom/DOM/ajaxForm";

import { domTooltips } from "vnet-dom/DOM/domTooltips";
import { domUnderRow } from "vnet-dom/DOM/domUnderRow";
import { domFocusElements } from "vnet-dom/DOM/domFocusElements";
import { domTabs } from "vnet-dom/DOM/domTabs";
import { domAddFromTemplate } from "vnet-dom/DOM/domAddFromTemplate";
import { domFormValidate } from "vnet-dom/DOM/domFormValidate";
import { domLazyLoad } from "vnet-dom/DOM/domLazyLoad";


import { clickEvents } from "./clickEvents";
import { setCompensateScrollbar } from "./setCompensateScrollbar";
import { initForms } from "./forms";
import { initSlick } from "./slick";
import { footerCollapseMobile } from "./footerCollapseMobile";
import { collapseCheckbox } from "./collapseCheckbox";
import { collapseCheckboxAll } from "./collapseCheckbox";
import { tabSelect } from "./tabSelect";
import { showMoreList } from "./showMoreList";
import { searchFilter } from "./searchFilter";
import { smallSearchTabs } from "./smallSearchTabs";
import { setInputDescPadding } from "./setInputDescPadding";
import { initMenu } from "./menu";
import { overMap } from "./overMap";
import { serverAction } from "./serverAction";
import { onLoadModa } from "./onLoadModa";
import { anchorMenu } from "./anchorMenu";
import { accordeon } from "./accordeon";
// import { appendCaptchaToken } from "./forms/validate";


export const dinamicFunctions = wrap => {
  let container = dom.getContainer(wrap);
  if (!container) return;

  tabSelect(container);

  initForms(container);
  initSlick(container);
  collapseCheckbox(container);
  collapseCheckboxAll(container);
  domLazyLoad(container);
  serverAction(container);


  ajaxLoadMore({ container, preloader, after: content => dinamicFunctions(content) });
  ajaxLoadOnView({ container, preloader, after: content => dinamicFunctions(content) });
  ajaxFancybox({ container, ajaxUrl: `${back_dates.ajax_url}?ajax_action=load_modal`, preloader, after: content => dinamicFunctions(content) });


  domTooltips({ container });
  domUnderRow({ container, onWindowLoad: true });
  domFocusElements({ container });

  domTabs(container);

  domAddFromTemplate({ container, after: html => dinamicFunctions(html) });
  domFormValidate({
    container,
    messages: dom.jsonParse(back_dates.validate_msg),
    addInputError,
    removeInputError,
    DEBUG: false,
    afterValidate: (form, isValid) => {
      return new Promise((resolve, reject) => {
        if (form.classList.contains('ajax-form')) {
          resolve(isValid);
        } else {
          if (isValid) {
            form.submit();
          }
        }
      });
    }
  });

  showMoreList(container);
  setInputDescPadding(container);
  overMap(container);

  anchorMenu(container);
  accordeon(container);

}



const staticFunctions = () => {
  onLoadModa();
  setCompensateScrollbar();
  clickEvents();
  footerCollapseMobile();
  searchFilter();
  ajaxForm({ preloader, createAlertModal, addInputError });
  smallSearchTabs();
  initMenu();
  dom.onWindowResize(e => {
    dom.dispatch('.bottom-arrow-item.active', 'set-arrow-size');
  });
}




staticFunctions();
dinamicFunctions();

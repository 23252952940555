import { dom } from "vnet-dom";



export const likeNumberInput = container => {
  let inputs = dom.findAll('.like-number-input', container);
  if (!inputs || !inputs.length) return;

  inputs.forEach(input => {
    setFilter(input);
  });
}



const setFilter = input => {
  let allowKeys = ['Enter', 'Backspace'];

  setInputValue(input);

  input.addEventListener('change', e => setInputValue(input));

  input.addEventListener('keydown', e => {
    let key = e.key;

    if (key.match(/[^\d]/) && !allowKeys.includes(key)) {
      e.preventDefault();
    }

    input.prevValue = input.value;
  });


  input.addEventListener('keyup', e => {

    if (!input.hasOwnProperty('prevValue')) return;

    if (input.value.match(/[^\d\s]+/)) {
      input.value = input.prevValue;
    }
    setInputValue(input);
  });
}


const setInputValue = input => {
  let val = parseFloat(input.value.replace(/[^\d]/g, ""));
  if (val) {
    input.value = val.toLocaleString();
  }
}
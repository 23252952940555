import { dom } from "vnet-dom";


export const overMap = container => {
  let items = dom.findAll('.over-map', container);
  if (!items || !items.length) return;

  items.forEach(item => init(item));
}



const init = item => {
  item.addEventListener('click', e => {
    dom.addClass(item, 'active');
  });
  item.addEventListener('mouseleave', e => {
    dom.removeClass(item, 'active');
  });
}
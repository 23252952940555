import { dom } from "vnet-dom";

export const initPhoneInputs = container => {
  let inputs = dom.findAll('input[data-phone]', container);
  if (!inputs || !inputs.length) return;

  inputs.forEach(input => {
    $(input).mask(input.dataset.phone, {

    });
  });
}
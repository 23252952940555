import {
    dom
} from "vnet-dom";

export const calculator = container => {
    var calculator = document.getElementById('calculator');
    if (calculator !== null) {
        var typeSelect = document.getElementById('type');
        var autoUp = document.getElementById('auto_up');
        var onMain = document.getElementById('on_main');
        var total = document.getElementById('calculator_total');
        var totalInput = document.getElementById('total_price');
        var button = document.getElementById('add-vacancy-button');

        typeSelect.onchange = function() {
            calculatorCalc(calculator, typeSelect, autoUp, onMain, total, totalInput, button);
        };

        autoUp.onchange = function() {
            calculatorCalc(calculator, typeSelect, autoUp, onMain, total, totalInput, button);
        };

        onMain.onchange = function() {
            calculatorCalc(calculator, typeSelect, autoUp, onMain, total, totalInput, button);
        };

        calculatorCalc(calculator, typeSelect, autoUp, onMain, total, totalInput, button);
    }
}

const calculatorCalc = (calculator, typeSelect, autoUp, onMain, total, totalInput, button) => {
    var sum = 0;
    switch(typeSelect.value) {
        case 'simple': { sum += parseInt(calculator.dataset.simple); } break;
        case 'standart': { sum += parseInt(calculator.dataset.standart); } break;
        case 'premium': { sum += parseInt(calculator.dataset.premium); } break;
    }

    if(autoUp.checked) sum += parseInt(autoUp.dataset.price);
    if(onMain.checked) sum += parseInt(onMain.dataset.price);
    total.innerText = sum;
    totalInput.value = sum;
    if(sum > 0) {
        button.innerText = button.dataset.pay;
    }
    else {
        button.innerText = button.dataset.submit;
    }
}

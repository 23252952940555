import { dom } from "vnet-dom";
import { React } from "vnet-dom/DOM/domReact";



const createPreloader = () => {
  return (
    <div className="ajax-preloader">
      <img src={back_dates.SRC + "img/ajax-preloader.svg"} alt="preloader" />
    </div>
  );
}



export const createAlertModal = html => {
  let modal = (
    <div className="alert-modal-content">
    </div>
  );
  modal.innerHTML = html;
  return modal;
}



export const preloader = createPreloader();
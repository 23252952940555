import { dom } from "vnet-dom";

export const resumeAccess = container => {
  let inputs = dom.findAll('.js-resume-access-period', container);
  if (!inputs || !inputs.length) return;

  inputs.forEach(input => {
    $(input).on('change', function(){
      $(this).closest('form').find('.js-resume-access-period-submit').attr('href', $(this).val());
    });
  });
}

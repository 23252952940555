import { dom } from "vnet-dom";
import { createAlertModal } from "./components";
import { dinamicFunctions } from "./";



export const parseServerResponse = res => {
  res = dom.jsonParse(res);
  if (!res) return false;

  if (res.redirect) {
    window.location.href = res.redirect;
  }

  if (res.alert) {
    let modal = createAlertModal(res.alert);
    $.fancybox.open(modal, {
      touch: false,
      afterShow: (instance, current) => {
        dinamicFunctions(current.src);
      }
    });
  }

  return res;
}
import { dom } from "vnet-dom";


export const setInputDescPadding = container => {
  let items = dom.findAll('.input-desc', container);
  if (!items || !items.length) return;
  items.forEach(item => setPadding(item));
  dom.onWindowResize(() => {
    items.forEach(item => setPadding(item));
  });
}


const setPadding = item => {
  if (window.innerWidth <= 576) return;

  let input = dom.findFirst('.input', item);
  let desc = dom.findFirst('.desc', item);
  if (!input || !desc) return;

  dom.addCss(input, { paddingRight: `${desc.offsetWidth}px` });
}

import { dom } from "vnet-dom";


export const showMoreList = container => {
  let items = dom.findAll('.js-show-more-list', container);

  if (!items || !items.length) return;

  items.forEach(item => init(item));
}




const init = btn => {
  let target = btn.dataset.target;
  let step = btn.dataset.step;

  let items = dom.findAll(target);
  if (!items || !items.length) return;

  displayItems(btn, items, 0);

  dom.onClick(btn, e => {
    e.preventDefault();
    displayItems(btn, items, step);
  });
}



const displayItems = (btn, items, step) => {
  step = parseInt(step);
  let viewed = items.filter(item => item.classList.contains('active')).length;
  let total = items.length;
  if (viewed === total) {
      btn.style.display = "none";
      return;
  }

  let max = viewed + step;
  max = max > total ? total : max;

  for (let i = 0; i < max; i++) {
     dom.addClass(items[i], 'active');
  }
  if(max == total) {
      btn.style.display = "none";
  }
}

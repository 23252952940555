import { dom } from "vnet-dom";

export const smscode = container => {
  let inputs = dom.findAll('#smscode-input', container);
  if (!inputs || !inputs.length) return;

  inputs.forEach(input => {
    $(input).on('change', function(){
        $('#smscode-hidden').val($(this).val());
    });
  });
}

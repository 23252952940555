import { initNiceSelect } from "./niceSelect";
import { initInputTypeImage } from "./typeImage";
import { initInputTypeDoc } from "./typeDoc";
import { initPhoneInputs } from "./typePhone";
import { likeNumberInput } from "./likeNumberInput";
import { formInputsGroup } from "./formInputsGroup";
import { smscode } from "./smscode";
import { speller } from "./speller";
import { resumeAccess } from "./resumeAccess";
import { calculator } from "./calculator";
import { searchTips } from "./searchTips";


export const initForms = container => {
  initNiceSelect(container);
  initInputTypeImage(container);
  initInputTypeDoc(container);
  initPhoneInputs(container);
  likeNumberInput(container);
  formInputsGroup(container);
  smscode(container);
  speller(container);
  resumeAccess(container);
  calculator(container);
  searchTips(container);
}

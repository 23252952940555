import { dom } from "vnet-dom";



export const tabSelect = container => {
  let items = dom.findAll('.tab-select', container);
  if (!items || !items.length) return;
  items.forEach(item => init(item));
}



const init = select => {
  $(select).on('change', e => {
    let val = select.value;
    let opt = dom.findFirst(`option[value=${val}]`, select);
    if (!opt) return;
    let tab = getOptTab(opt);
    if (!tab) return false;
    changeTab(select, tab);
  });
}



const changeTab = (select, tab) => {
  let current = getCurrentTab(select);
  if (current) {
    dom.removeClass(current, 'active');
  }
  if (tab) {
    dom.addClass(tab, 'active');
  }
}





const getCurrentTab = select => {
  let opts = dom.findAll('option', select);
  let total = opts.length;
  for (let i = 0; i < total; i++) {
    let tab = getOptTab(opts[i]);
    if (tab.classList.contains('active')) return tab;
  }
  return false;
}


const getAllTabs = select => {
  let opts = dom.findAll('option', select);
  let res = [];
  opts.forEach(opt => {
    let tab = getOptTab(opt);
    if (!tab) return;
    res.push(tab);
  });
  return res;
}




const getOptTab = opt => {
  let tab = opt.dataset.tab;
  if (!tab) return false;
  tab = dom.findFirst(tab);
  if (!tab) return false;
  return tab;
}
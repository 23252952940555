import { dom } from "vnet-dom";


export const collapseCheckbox = container => {
  let items = dom.findAll('.collapse-checkbox', container);
  if (!items || !items.length) return;

  items.forEach(item => {
    item.addEventListener('change', e => {
      toggleCollapse(item);
    });
  });
}

export const collapseCheckboxAll = container => {
  let items = dom.findAll('.collapse-checkbox-all', container);
  if (!items || !items.length) return;

  items.forEach(item => {
    item.addEventListener('change', e => {
      toggleCollapseAll(container, item);
    });
  });
}



const toggleCollapse = item => {
  let target = item.dataset.target;
  if (!target) return;
  target = dom.findFirst(target);
  if (!target) return;
  if (item.checked) {
    if (item.dataset.check === 'collapse') {
      $(target).slideUp({ duration: 150, complete: () => {
          dom.removeClass(target, 'active');
          dom.removeClass(dom.findFirst('.toggle-collapse', target.parentNode), 'active');
      } });
    } else {
      $(target).slideDown({ duration: 150, complete: () => {
          dom.addClass(target, 'active');
          dom.addClass(dom.findFirst('.toggle-collapse', target.parentNode), 'active');
      } });
    }
  } else {
    if (item.dataset.check === 'collapse') {
      $(target).slideDown({ duration: 150, complete: () => {
          dom.addClass(target, 'active');
          dom.addClass(dom.findFirst('.toggle-collapse', target.parentNode), 'active');
      } });
    } else {
      $(target).slideUp({ duration: 150, complete: () => {
          dom.removeClass(target, 'active');
          dom.removeClass(dom.findFirst('.toggle-collapse', target.parentNode), 'active');
      } });
    }
  }
}

const toggleCollapseAll = (container, item) => {
  let target = dom.findAll('.collapse-optional', container);
  if (!target) return;
  if (item.checked) {
    if (item.dataset.check === 'collapse') {
      $(target).slideUp({ duration: 150, complete: () => dom.removeClass(target, 'active') });
    } else {
      $(target).slideDown({ duration: 150, complete: () => dom.addClass(target, 'active') });
    }
  } else {
    if (item.dataset.check === 'collapse') {
      $(target).slideDown({ duration: 150, complete: () => dom.addClass(target, 'active') });
    } else {
      $(target).slideUp({ duration: 150, complete: () => dom.removeClass(target, 'active') });
    }
  }
}

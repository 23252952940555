import { dom } from "vnet-dom";
import { preloader } from "../components";


export const formInputsGroup = container => {
  let forms = dom.findAll('.form-inputs-group', container);
  if (!forms || !forms.length) return;

  forms.forEach(form => {
    init(form);
  });

}



const init = form => {
  // form.addEventListener('inputs-group-collapse', e => {
  //   let checkbox = e.target;
  //   let target = checkbox.dataset.target;
  //   target = dom.findFirst(target, form);
  //   if (!target) return;
  //
  //   if (checkbox.checked) {
  //     dom.addClass(target, 'disabled', form);
  //   } else {
  //     dom.removeClass(target, 'disabled', form);
  //   }
  // });
  form.addEventListener('submit', e => {
    e.preventDefault();
    initSubmit(form);
  });

  if(dom.findFirst('#category-parent') !== null) {
      let category_parent = dom.findFirst('#category-parent');
      category_parent.onchange = function() {
          let category_select = dom.findFirst('#category');
          let url = '/categories/' + this.value;
          let data;
          if(this.value.length) {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, false);
            xhr.send();
            if (xhr.status == 200) {
                //alert( xhr.responseText );
                category_select.disabled = false;
                var length = category_select.options.length;
                for (var i = length-1 ; i >= 0 ; i--) {
                  category_select.options[i] = null;
                }
                var option = document.createElement('option');
                option.value = "";
                option.innerText = "Выберите профессию";
                category_select.appendChild(option);
                var options = $.parseJSON(xhr.responseText);
                for(var i = 0 ; i < options.length ; i++) {
                    var option = document.createElement('option');
                    option.value = options[i].id;
                    option.innerText = options[i].name;
                    category_select.appendChild(option);
                }
                $(category_select).niceSelect('update');
            }
          }
          else {
              var length = category_select.options.length;
              for (var i = length-1 ; i >= 0 ; i--) {
                category_select.options[i] = null;
              }
              var option = document.createElement('option');
              option.value = "";
              option.innerText = "Выберите область деятельности";
              category_select.appendChild(option);
              category_select.disabled = true;
              $(category_select).niceSelect('update');
          }
      }
  }
  dom.onClick('.delete-template', function (e) {
      e.preventDefault();
      this.parentNode.parentNode.removeChild(this.parentNode);
  });
}





const initSubmit = form => {
  let data = getFormData(form);
  let url = form.getAttribute('action');
  dom.ajax({ url, data: { data: JSON.stringify(data) }, preloader: form, preloaderHTML: preloader, minTimeResponse: 300 })
    .then(res => {
      console.log(res);
    });
}



const getFormData = form => {
  let groups = dom.findAll('[data-inputs-group]', form);
  if (!groups || !groups.length) return;

  let res = {};

  groups.forEach(group => {
    res[group.dataset.inputsGroup] = getInputsGroup(group);
  });

  return res;
}




const getInputsGroup = group => {
  let checkCollapse = dom.findFirst('.legend .collapse-checkbox', group);
  if (checkCollapse) {
    if (checkCollapse.dataset.check === 'collapse' && checkCollapse.checked) return false;
    if (checkCollapse.dataset.check !== 'collapse' && !checkCollapse.checked) return false;
  }

  let blocks = dom.findAll('.js-inputs-group', group);
  if (!blocks || !blocks.length) return false;

  let res = [];

  blocks.forEach(block => {
    res.push(getInputsValues(block));
  });

  return res;
}




const getInputsValues = block => {
  let inputs = dom.findAll('input, select, textarea', block);
  if (!inputs || !inputs.length) return false;

  let res = {};

  inputs.forEach(input => {
    let val = input.value;
    if (input.type === 'checkbox' || input.type === 'radio') val = input.checked;

    let id = input.name;
    if (input.type === 'checkbox' || input.type === 'radio') id = input.id;

    res[id] = val;

    input.on('change', function(){
        $(this).removeClass('has-error');
    });
  });

  return res;
}

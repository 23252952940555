import { dom } from "vnet-dom";
import { recaptcha } from "./recaptcha";


export const addInputError = (input, msg) => {
  let help = getCreateHelp(input);
  help.innerHTML = msg;
  dom.addClass(input, 'has-error');
  input.dataset.errorMsg = msg;
  dom.dispatch(input, 'validate-add-error');
}



export const removeInputError = input => {
  dom.removeClass(input, 'has-error');
  let help = dom.findFirst('.input-help', input.parentNode);
  if (help) help.innerHTML = '';
  input.removeAttribute('data-error-msg');
  dom.dispatch(input, 'validate-rm-error');
}



export const appendCaptchaToken = (form, isValid) => {
  return new Promise((resolve, reject) => {
    recaptcha(form).then(() => resolve(isValid));
  });
}




const getCreateHelp = input => {
  let help = dom.findFirst('.input-help', input.parentNode);
  if (!help) {
    help = dom.create('span', { className: 'input-help' });
    input.parentNode.appendChild(help);
  }
  return help;
}




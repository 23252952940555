import { dom } from "vnet-dom";
import { parseServerResponse } from "./parseServerResponse";
import { preloader } from "./components";


export const serverAction = container => {
  dom.onClick('[data-ajax-action]', e => {
    e.preventDefault();
    let btn = e.target;
    init(btn);
  }, container);
}



const init = btn => {
  if (btn.classList.contains('in-action')) return;
  dom.addClass(btn, 'in-action');

  let action = btn.dataset.ajaxAction;
  let preloadContainer = btn.dataset.preload ? dom.findFirst(btn.dataset.preload) : false;
  let data = btn.dataset.ajaxData ? { data: btn.dataset.ajaxData } : false;

  dom.ajax({ url: action, data: data, preloader: preloadContainer, preloaderHTML: preloader })
    .then(res => {
      dom.removeClass(btn, 'in-action');
      res = parseServerResponse(res);
    });
}
import { dom } from "vnet-dom";

export const anchorMenu = container => {
  let items = dom.findAll('.cats-anchor-menu a', container);
  if (!items || !items.length) return;

  items.forEach(item => {
    // $(item).on('click', function(e){
    //     e.preventDefault();
    //     let target = dom.findFirst('a[name="' + $(this).data('target') + '"]', container);
    //     $('html, body').animate({
    //         scrollTop: $(target).offset().top - 90
    //     }, 500);
    // });
  });
}

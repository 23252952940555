import { dom } from "vnet-dom";


export const smallSearchTabs = () => {
  let section = dom.findFirst('.section-search_small');
  if (!section) return;

  let links = dom.findAll('.tab-link', section);
  let select = dom.findFirst('.tab-select', section);
  if (!select || !links || !links.length) return;

  section.addEventListener('dom-tab-change', e => {
    let currentId = e.detail.current;
    let option = dom.findFirst(`option[data-tab="${currentId}"]`, section);
    if (!option) return;
    select.value = option.value;
    $(select).niceSelect('update');
  });

  $(section).on('change', e => {
    let current = select.value;
    let option = dom.findFirst(`option[value="${current}"]`, section);
    if (!option) return;
    let currentId = option.dataset.tab;
    let activeLink = dom.findFirst(`a[href="${currentId}"]`, section);
    if (!activeLink) return;
    dom.dispatch(activeLink, 'click');
  });
}
import { dom } from "vnet-dom";


export const initNiceSelect = container => {
  let items = dom.findAll('.custom-select', container);
  if (!items || !items.length) return;

  items.forEach(item => {

    $(item).niceSelect();
    let customSelect = dom.findFirst('.nice-select', item.parentNode);

    item.addEventListener('validate-add-error', e => {
      addError(customSelect, item.dataset.errorMsg);
    });

    item.addEventListener('validate-rm-error', e => {
      removeError(customSelect);
    });

  });
}




const addError = (item, msg) => {
  dom.addClass(item, 'has-error');
}



const removeError = item => {
  dom.removeClass(item, 'has-error');
}
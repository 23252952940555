import { dom } from "vnet-dom";

import { slickOnBreakpoint } from "./";





export const companyVacanciesSlider = container => {
  let slides = dom.findAll('.slick-company-vac', container);
  if (!slides || !slides.length) return;

  slides.forEach(slider => {
    slickOnBreakpoint(576, $(slider), slickSets());
  });
}


const slickSets = () => {
  return {
    autoplay: true,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    centerMode: true,
    centerPadding: '100px',
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '120px'
        }
      },
      {
        breakpoint: 420,
        settings: {
          centerPadding: '50px'
        }
      },
      {
        breakpoint: 350,
        settings: {
          centerPadding: '40px'
        }
      }
    ]
  }
}
